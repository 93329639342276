<template>
  <div>
    <Modal :options="{width: '40vw',type: (tipo == 'aceptado' ? 'success' : 'danger'),esc:false}">
      <div class="title">{{ tipo == 'aceptado' ? 'Aceptar' : 'Rechazar' }} solicitud {{ solicitud.solicitud_id }}</div>
      <div class="body">
        <div class="row form-group">
          <label for="monto_aceptado" class="col-form-label col-sm-3">Monto aceptado</label>
          <div class="col-sm-9">
            <InputCompuesto v-model="cesion.monto_aceptado" id="monto_aceptado" name="monto_aceptado" label="$" :formats="['moneyFormat']"/>
          </div>
        </div>
        <div class="row form-group">
          <label for="plazo_aceptado" class="col-form-label col-sm-3">Plazo</label>
          <div class="col-sm-9">
            <InputCompuesto v-model="cesion.plazo_aceptado" id="plazo_aceptado" name="plazo_aceptado" label_right="meses"/>
          </div>
        </div>
        <div class="row form-group">
          <label for="observaciones" class="col-form-label col-sm-3">Observaciones</label>
          <div class="col-sm-9">
            <textarea v-model="cesion.observaciones" name="observaciones" id="observaciones" cols="30" rows="10" class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="resolucion">Aceptar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import InputCompuesto from '@/components/Form/InputCompuesto';

import api from '@/apps/analyticpoint/api/comite/cesiones';

export default {
  components: {
    Modal, InputCompuesto
  },
  props: {
    cesion_id: {
      type: String
    },
    solicitud: {
      type: Object,
      default() {
        return {
          id: '2222',
          solicitud_id: 28590,
          monto_final: 5000000
        }
      }
    },
    tipo: {
      type: String
    }
  },
  data() {
    return {
      cesion: {
        monto_aceptado: null,
        observaciones: null,
        resolucion: null,
        plazo_aceptado: 0
      }
    }
  },
  mounted() {
    this.cesion.monto_aceptado = this.solicitud.monto_final;
    this.cesion.plazo_aceptado = this.solicitud.plazo_final;
    this.cesion.resolucion = this.tipo;
  },
  methods: {
    async resolucion() {
      try {
        let res = (await api.resolver_solicitud(this.cesion_id, this.cesion));
        this.$log.info('res', res);
        this.$emit('finalizar');
      }catch(e) {
        this.$log.error(e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
}
</script>