import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_ANALYTICPOINT+'/cesiones');

export default {
  obtener_cesiones(listar, opciones) {
    let url = listar != 'todas' ? '/solicitudes' : utils.url_options('/solicitudes', opciones);
    url += url.indexOf('?') > -1 && listar == 'todas' ? '&all=1' : '';
    return http.get(url);
  },

  obtener_cesiones_pendientes(all) {
    return http.get('/pendientes?all='+all);
  },

  cargar_alianzas() {
    return http.get(`/alianzas`);
  },

  ceder_solicitud(payload) {
    return http.post(`/`,payload);
  },

  resolver_solicitud(id, payload) {
    return http.put(`/${id}`, payload);
  }
}
